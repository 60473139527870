<template>
    <div class="sections_container">
        <section v-for="(sec, i) in payload.sections" :key="i">
            <b-container>
                <b-row align-v="center" align-h="between" :class="i % 2 != 0 ? 'flex-row-reverse' : ''">
                    <b-col cols="12" lg="5">
                        <img :src="sec.image.url" :class="i % 2 == 0 ? 'odd' : 'even'" />
                    </b-col>

                    <b-col class="text-content" cols="12" lg="6">
                        <h6 v-if="sec.title_prefix" class="mb-0">{{ sec.title_prefix }}</h6>
                        <h2 v-if="sec.title" class="mb-5">{{ sec.title }}</h2>
                        <p v-if="sec.description" class="mt-5">{{ sec.description }}</p>
                        <b-link v-if="sec.link" :href="getLink(sec.link)" :target="sec.link.target" class="my-5">
                            <b-button variant="dark">{{ sec.link.title }}</b-button>
                        </b-link>
                    </b-col>
                </b-row>
            </b-container>
        </section>
    </div>
</template>

<script>
    export default {
        name: "ContentSections",
        props: ['payload'],
        methods: {
          getLink (link) {
            if(link.url && link.url.includes("http")) {
              return link.url
            } else {
              return process.env.VUE_APP_DOMAIN + link.url
            }
          }
        },
    }
</script>

<style scoped lang="scss">
    a { display: block; }

    .sections_container {
        background: $gray-100;
        padding-bottom: 120px;
        overflow: hidden;

        @include media-breakpoint-up(lg){
            padding-top: 160px;
            padding-bottom: 260px;
        }

        img{
            margin-left: -12px;
            margin-right: -12px;
            width: calc(100% + 24px);
            margin-bottom: 48px;
            max-width: initial;

            @include media-breakpoint-up(xl){
                $outside: calc( (100vw - #{get-breakpoints("xl")} - 24px) / 2 ); // width of margin between edge of the browser window and grid

                width: calc(100% + #{$outside} + 12px + 24px);

                &.odd{ margin-left: calc(-1 * #{$outside} - 24px); }
                &.even{}
            }
        }
    }

    section {
        margin-bottom: 104px;
        &:last-child { margin-bottom: 0; }

        .text-content{
            h6 {
                color: $primary;
                margin-bottom: 4px;
            }

            p:last-of-type{ margin-bottom: 0; }
        }
    }

    .contrast .sections_container{
        h6{ font-weight: 600; }
    }
</style>
